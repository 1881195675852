<template>
<div v-if="isShow">
    <div class="userInfo">
        <div class="avator" @mouseenter="showCamera"  @mouseleave="disCamera">

            <span v-show="isCamera"  @click="console" class="photoMast"><img  src="../../assets/images/照相机.png"></span>
            <img  v-if="havaAvator()"  :src="userInfo.photo" >
            <img  v-else  src="../../../public/images/toux.jpg">
              <input type="file" accept="image/*" @change="onFileChange" v-show="false" ref="fileRef" />
        </div>
        <div class="userpro">
            <div class="username">
                <h2>{{userInfo.username}}</h2>
                <span>个性签名：</span>
                <p class="person">{{userInfo.motto}}</p>
            </div>
        </div>
    </div>
    <div class="basic">
        <h3>基本信息</h3>
        <div class="basicInfo">
            <ul>
                <li>
                    <div class="content-show-l">用户名</div>
                    <div class="content-show-r">{{userInfo.username}}</div>

                    <div class="edit"></div>
                </li>
                <li>
                    <div class="content-show-l">手机号</div>
                    <div class="content-show-r">{{userInfo.tel}}</div>
                </li>
                <li>
                    <div class="content-show-l">性别</div>
                    <div class="content-show-r">{{userInfo.sex}}</div>
                </li>
                <li>
                    <div class="content-show-l">出生日期</div>
                    <div class="content-show-r">{{userInfo.birthday}}</div>
                </li>
                <li>
                    <div class="content-show-l">学校</div>
                    <div class="content-show-r">{{userInfo.school}}</div>
                </li>
                <li>
                    <div class="content-show-l">学院</div>
                    <div class="content-show-r">{{userInfo.department}}</div>
                </li>
                 <li>
                    <div class="content-show-l">学号</div>
                    <div class="content-show-r">{{userInfo.stu_id}}</div>
                </li>


            </ul>
        </div>
    </div>
</div>
</template>

<script>
import { changeAvater } from '../../API/User.js'
import { mapActions } from 'vuex'
export default {

  name: 'userInfo',
  data () {
    return {
      isShow: true,
      isCamera: false,
      sex: '男'
    }
  },
  methods: {
    ...mapActions(['initUserInfo', '$base64Img']),
    isSex () {
      if (this.userInfo.sex === 0) {
        this.sex = '男'
      } else {
        this.sex = '女'
      }
    },
    showCamera () {
      this.isCamera = true
    },
    disCamera () {
      this.isCamera = false
    },
    console () {
      this.$refs.fileRef.click()
    },
    // 默认头像
    havaAvator () {
      // console.log(this.userInfo.username.)
      if (this.userInfo.photo !== null) {
        return true
      } else {
        return false
      }
    },
    // 上传用户头像
    onFileChange (e) { // 通过input标签选择文件传入e
      this.$base64Img(e).then(async (res) => { // 调用全局方法
        if (res.status !== 0) {
          this.$message({
            type: 'error',
            message: res.message
          })
        } else {
          var obj = {
            user_pic: res.data
          }
          // 将base64传入数据库
          const { data: result } = await changeAvater(obj)
          // 重新获取用户信息
          this.initUserInfo()
          if (result.status === 0) {
            this.$message({
              type: 'success',
              message: result.message
            })
          } else {
            this.$message({
              type: 'error',
              message: result.message
            })
          }
        }
      })
    }
  },
  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    userInfo: function () {
      // console.log(this.$store.state.userInfo)
      return this.$store.state.userInfo
    }
  }

}
</script>

<style lang="less" scoped>
html,body{
    background-color: #fff;
}
li{
    list-style: none;
}
a{
    text-decoration: none;
}
.userInfo{
    background-color: #fff;
}
 .userInfo{
        display: flex;
        flex: 5;

        align-items: center;
        padding-left: 0.5rem;
        .avator{
            width: 1.5rem;
            height: 1.5rem;
            position: relative;
            .photoMast{
                cursor: pointer;
                position:absolute;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                z-index: 5;
                background-color: rgba(0,0,0,.4);
                img{
                    width: 30%;
                    height: 30%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                }
            }
            // border-radius: 50%;
            img{
                width: 100%;
                height: 100%;
                cursor: pointer;
                border-radius: 50%;
            }
        }
       .userpro{
           padding-left: .5833rem;
           display: flex;
           flex-direction: column;
           justify-content: flex-start;
           .username{
            //    display: flex;
            //    align-items: center;
            h2{
               text-align: left;
               margin: 0;
               margin-top: .4167rem;
           }
            .person{
                padding: 10px .4167rem;
                background-color: #F6F6F6;
                display: inline-block;
                }
                }

       }
    }

    .basic{
        background-color: #fff;
        padding: 0 0 0.1rem 0;
        h3{
            text-align: left;
            height: 48px;
            line-height: 48px;
            font-size: 18px;
            font-weight: 600;
            color: #2e2e2e;
            padding-left: 16px;
            margin: 10px 0;
            border-bottom: 1px solid #f0f0f2
        }
        .basicInfo{
            ul{
                li{
                    line-height: 24px;
                    font-size: 14px;
                    margin-bottom: 32px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: baseline;
                    -ms-flex-align: baseline;
                    align-items: baseline;
                    .content-show-l{
                        width: 56px;
                        color: #555666;
                        margin-right: 40px;
                        -ms-flex-negative: 0;
                        flex-shrink: 0;
                        padding-left: 16px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-sizing: content-box;
                        box-sizing: content-box;
                        -webkit-box-pack: justify;
                        -ms-flex-pack: justify;
                        justify-content: space-between;
                    }
                    .content-show-r{
                            color: #222226;
                            word-break: break-all;
                    }
                    }
                }

            }
        }

</style>
